import { useAuth } from '@clerk/nextjs'
import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query'
import { TypedDocumentNode, print } from '@thenarrative/dto-types'
import { fetcher } from '@thenarrative/graphql-client'
import { ApplicationEnvironment } from '../utils/ApplicationEnvironment'
import { Logger } from '../utils/logger'

const logger = Logger.create('useAuthenticatedQuery')

type AuthenticatedQueryProps<TResult, TVariables> = {
  query: TypedDocumentNode<TResult, TVariables>
  queryKey: QueryKey
  variables?: TVariables
  enabled?: boolean
  onSuccess?: (data: TResult) => void
  organizationId?: string
  refetchInterval?: number | false
  refetchIntervalInBackground?: boolean
  refetchOnWindowFocus?: boolean
}

export function useAuthenticatedQuery<TResult, TVariables>({
  queryKey,
  query,
  variables,
  enabled = true,
  onSuccess,
  organizationId,
  refetchInterval = false,
  refetchIntervalInBackground = false,
  refetchOnWindowFocus = false,
}: AuthenticatedQueryProps<TResult, TVariables>): UseQueryResult<TResult> {
  const { getToken, isSignedIn } = useAuth()

  // NOTE: if query is disabled it will still be in isLoading=true state in some cases
  // so we need to check if fetchStatus is in idle
  return useQuery({
    queryKey,
    queryFn: async () => {
      try {
        const token = await getToken()

        const response = await fetcher({
          baseURL: ApplicationEnvironment.GRAPHQL_API_URL,
          query: print(query),
          variables: variables ?? {},
          accessToken: token ?? '',
          organizationId,
        })

        return response
      } catch (e) {
        logger.error((e as Error).message, e)
        throw e
      }
    },
    onSuccess: onSuccess,
    retry: 3,
    retryDelay: 500,
    refetchInterval,
    refetchIntervalInBackground,
    refetchOnWindowFocus,
    enabled: isSignedIn && enabled,
  })
}
