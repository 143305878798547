import { FC, PropsWithChildren } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

type Props = PropsWithChildren<{
  date?: Date
}>

interface NodeProps {
  node?: any
  [key: string]: any
}

export const Markdown: FC<Props> = ({ children }) => {
  const components = {
    h1: ({ node, ...props }: NodeProps) => <h1 className='text-xl font-bold' {...props} />,
    h2: ({ node, ...props }: NodeProps) => <h2 className='text-m font-bold' {...props} />,
    ul: ({ node, ...props }: NodeProps) => <ul className='list-disc list-inside pl-4' {...props} />,
    ol: ({ node, ...props }: NodeProps) => <ol className='list-decimal list-inside pl-4' {...props} />,
    li: ({ node, ...props }: NodeProps) => <li className='mb-1' {...props} />,
    strong: ({ node, ...props }: NodeProps) => <strong className='font-bold' {...props} />,
    em: ({ node, ...props }: NodeProps) => <em className='italic' {...props} />,
    pre: ({ node, ...props }: NodeProps) => <pre className='overflow-auto' {...props} />,
  }

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
      {children as string}
    </ReactMarkdown>
  )
}
