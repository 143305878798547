import { graphql } from '../../dto-types/src/gql/gql'

export const UPDATE_USER_ROLE = graphql(`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
      role
    }
  }
`)

export const UPDATE_USER = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`)

export const CREATE_ORGANIZATION = graphql(`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      teamUrl
    }
  }
`)

export const UPDATE_ORGANIZATION = graphql(`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
      logoUrl
    }
  }
`)

export const UPDATE_ORGANIZATION_METADATA = graphql(`
  mutation UpdateOrganizationMetadata($input: UpdateOrganizationMetadataInput!) {
    updateOrganizationMetadata(input: $input) {
      id
      metadata
    }
  }
`)

export const DELETE_ORGANIZATION = graphql(`
  mutation DeleteOrganization($organizationId: String!) {
    deleteOrganization(organizationId: $organizationId)
  }
`)

export const LEAVE_ORGANIZATION = graphql(`
  mutation LeaveOrganization($organizationId: String!) {
    leaveOrganization(organizationId: $organizationId)
  }
`)

export const CREATE_SPACE = graphql(`
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      name
      spaceUrl
    }
  }
`)

export const DELETE_SPACE = graphql(`
  mutation DeleteSpace($id: String!) {
    deleteSpace(id: $id)
  }
`)

export const ARCHIVE_SPACE = graphql(`
  mutation ArchiveSpace($input: ArchiveSpaceInput!) {
    archiveSpace(input: $input)
  }
`)

export const CREATE_PROJECT = graphql(`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      name
    }
  }
`)

export const DELETE_PROJECT = graphql(`
  mutation DeleteProject($id: String!) {
    deleteProject(id: $id)
  }
`)

export const ARCHIVE_PROJECT = graphql(`
  mutation ArchiveProject($id: String!) {
    archiveProject(id: $id)
  }
`)

export const CREATE_PROJECT_TASK = graphql(`
  mutation CreateProjectTask($input: CreateProjectTaskInput!) {
    createProjectTask(input: $input) {
      id
      asset {
        id
        name
      }
      project {
        projectUrl
        space {
          spaceUrl
        }
      }
    }
  }
`)

export const UPDATE_PROJECT_TASK = graphql(`
  mutation UpdateProjectTask($input: UpdateProjectTaskInput!) {
    updateProjectTask(input: $input) {
      id
      reviewer {
        id
        firstName
        lastName
        avatarUrl
      }
      dueDate
      priority
    }
  }
`)

export const ARCHIVE_PROJECT_TASK = graphql(`
  mutation ArchiveProjectTask($id: String!) {
    archiveProjectTask(id: $id) {
      id
    }
  }
`)

export const CREATE_PROJECT_TASK_ATTACHMENTS = graphql(`
  mutation createProjectTaskAttachments($input: [CreateTaskAttachmentInput!]!) {
    createProjectTaskAttachments(input: $input) {
      id
    }
  }
`)

export const CREATE_ACTIVITY_TASK_ATTACHMENTS = graphql(`
  mutation CreateActivityTaskAttachments($input: [CreateTaskAttachmentInput!]!) {
    createActivityTaskAttachments(input: $input) {
      id
    }
  }
`)

export const UPDATE_PROJECT_ASSET = graphql(`
  mutation updateProjectAsset($input: UpdateProjectAssetInput!) {
    updateProjectAsset(input: $input) {
      id
      state
    }
  }
`)

export const UPDATE_PROJECT_TASK_STATUS = graphql(`
  mutation UpdateProjectTaskStatus($input: UpdateProjectTaskStatusInput!) {
    updateProjectTaskStatus(input: $input) {
      id
      status
    }
  }
`)

export const BUMP_ASSET_VERSION = graphql(`
  mutation BumpAssetVersion($input: BumpAssetVersionInput!) {
    bumpAssetVersion(input: $input) {
      id
    }
  }
`)

export const CREATE_OR_UPDATE_DOCUMENT_ASSET_REVISIONS = graphql(`
  mutation CreateOrUpdateDocumentAssetRevisions($input: COUDocumentAssetRevisionInput!) {
    createOrUpdateDocumentAssetRevisions(input: $input) {
      id
      externalId
      name
      createdAt
      creatorId
      authorsIds
      attributes
      fromVersion
      toVersion
      diffData
      updatedAt
    }
  }
`)

export const CREATE_ASSET_REVIEW_REQUEST = graphql(`
  mutation CreateAssetReviewRequest($input: CreateReviewRequestInput!) {
    reviewRequest(input: $input) {
      id
    }
  }
`)

export const UPDATE_ASSET_REVIEW_REQUEST = graphql(`
  mutation UpdateAssetReviewRequest($input: UpdateReviewRequestInput!) {
    updateReviewRequest(input: $input) {
      id
      status
    }
  }
`)

export const INVITE_ORGANIZATION_MEMBERS = graphql(`
  mutation InviteOrganizationMembers($input: CreateOrganizationInvitationsInput!) {
    createOrganizationInvitations(input: $input) {
      failedItems {
        email
        message
      }
    }
  }
`)

export const INVITE_ORGANIZATION_MEMBER = graphql(`
  mutation InviteOrganizationMember($input: CreateOrganizationInvitationInput!) {
    createOrganizationInvitation(input: $input) {
      id
      email
      role
    }
  }
`)

export const CANCEL_ORGANIZATION_INVITATION = graphql(`
  mutation CancelInvitation($input: CancelOrganizationInvitationInput!) {
    cancelOrganizationInvitation(input: $input)
  }
`)

export const ACCEPT_ORGANIZATION_INVITATION = graphql(`
  mutation AcceptOrganizationInvitation($input: AcceptOrganizationInvitationInput!) {
    acceptOrganizationInvitation(input: $input) {
      id
      email
      role
      organization {
        name
        teamUrl
      }
    }
  }
`)

export const INVITE_PROJECT_MEMBERS = graphql(`
  mutation InviteProjectMembers($input: CreateProjectInvitationsInput!) {
    createProjectInvitations(input: $input) {
      failedItems {
        email
        message
      }
    }
  }
`)

export const INVITE_SPACE_MEMBERS = graphql(`
  mutation InviteSpaceMembers($input: CreateSpaceInvitationsInput!) {
    createSpaceInvitations(input: $input) {
      failedItems {
        email
        message
      }
    }
  }
`)

export const ACCEPT_SPACE_INVITATION = graphql(`
  mutation AcceptSpaceInvitation($uniqueId: String!) {
    acceptSpaceInvitation(uniqueId: $uniqueId) {
      id
      email
      role
      space {
        name
        spaceUrl
        isOnboarded
        organization {
          organizationProducts {
            product {
              id
              name
            }
          }
          teamUrl
        }
      }
    }
  }
`)

export const ACCEPT_PROJECT_INVITATION = graphql(`
  mutation AcceptProjectInvitation($uniqueId: String!) {
    acceptProjectInvitation(uniqueId: $uniqueId) {
      id
      email
      role
      project {
        name
        projectUrl
        space {
          spaceUrl
          organization {
            teamUrl
          }
        }
      }
    }
  }
`)

export const CANCEL_PROJECT_INVITATION = graphql(`
  mutation CancelProjectInvitation($uniqueId: String!) {
    cancelProjectInvitation(uniqueId: $uniqueId)
  }
`)

export const CANCEL_SPACE_INVITATION = graphql(`
  mutation CancelSpaceInvitation($uniqueId: String!) {
    cancelSpaceInvitation(uniqueId: $uniqueId)
  }
`)

export const CREATE_WORKFLOW_TEMPLATE = graphql(`
  mutation CreateWorkflowTemplate($input: CreateWorkflowTemplateInput!) {
    createWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const UPDATE_WORKFLOW_TEMPLATE = graphql(`
  mutation UpdateWorkflowTemplate($input: UpdateWorkflowTemplateInput!) {
    updateWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const CREATE_PROJECT_WORKFLOW_TEMPLATE = graphql(`
  mutation CreateProjectWorkflowTemplate($input: CreateProjectWorkflowTemplateInput!) {
    createProjectWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const UPDATE_PROJECT_WORKFLOW_TEMPLATE = graphql(`
  mutation UpdateProjectWorkflowTemplate($input: UpdateProjectWorkflowTemplateInput!) {
    updateProjectWorkflowTemplate(input: $input) {
      id
      name
      nodes {
        payload
        children {
          id
          name
        }
        createdAt
        deletedAt
        id
        name
        type
        updatedAt
      }
    }
  }
`)

export const DELETE_WORKFLOW_TEMPLATE = graphql(`
  mutation DeleteWorkflowTemplate($id: String!) {
    deleteWorkflowTemplate(id: $id)
  }
`)

export const ARCHIVE_WORKFLOW_TEMPLATE = graphql(`
  mutation ArchiveWorkflowTemplate($id: String!) {
    archiveWorkflowTemplate(id: $id)
  }
`)

export const UPDATE_WORKFLOW_TEMPLATE_ACTIVE_STATUS = graphql(`
  mutation UpdateWorkflowTemplateActiveStatus($id: String!, $active: Boolean!) {
    updateWorkflowTemplateActiveStatus(id: $id, active: $active)
  }
`)

export const UPDATE_SPACE = graphql(`
  mutation UpdateSpace($id: String!, $input: UpdateSpaceInput!) {
    updateSpace(id: $id, input: $input) {
      id
      name
      description
    }
  }
`)

export const UPDATE_SPACE_USER = graphql(`
  mutation UpdateSpaceUser($id: String!, $input: UpdateSpaceUserInput!) {
    updateSpaceUser(id: $id, input: $input) {
      id
      role
    }
  }
`)

export const CREATE_COMMENT_THREAD = graphql(`
  mutation CreateCommentThread($input: CreateCommentThreadInput!) {
    createCommentThread(input: $input) {
      id
      externalId
      attributes
      context
      resolvedAt
      resolvedBy {
        id
      }
      comments {
        id
        externalId
        content
        createdAt
        author {
          id
        }
        attributes
      }
    }
  }
`)

export const UPDATE_COMMENT_THREAD = graphql(`
  mutation UpdateCommentThread($input: UpdateCommentThreadInput!) {
    updateCommentThread(input: $input) {
      id
      externalId
      attributes
      context
      resolvedAt
      resolvedBy {
        id
      }
      comments {
        id
        externalId
        content
        createdAt
        author {
          id
        }
        attributes
      }
    }
  }
`)

export const DELETE_COMMENT_THREAD = graphql(`
  mutation DeleteCommentThread($input: DeleteCommentThreadInput!) {
    deleteCommentThread(input: $input)
  }
`)

export const CREATE_COMMENT = graphql(`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      attributes
      author {
        id
      }
      content
      createdAt
      externalId
      id
    }
  }
`)

export const UPDATE_COMMENT = graphql(`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      attributes
      author {
        id
      }
      content
      createdAt
      externalId
      id
    }
  }
`)

export const DELETE_COMMENT = graphql(`
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input)
  }
`)

export const ADVANCE_WORKFLOW = graphql(`
  mutation AdvanceWorkflow($taskId: String!) {
    advanceWorkflow(taskId: $taskId) {
      id
      name
      status
    }
  }
`)

export const UPDATE_NOTIFICATION_PREFERENCES_PROJECT = graphql(`
  mutation UpdateNotificationPreferencesProject($input: UpdateNotificationPreferencesProjectInput!) {
    updateNotificationPreferencesProject(input: $input) {
      id
      projectId
      userId
      notifyTasks
      notifyComments
      notifyStatusUpdates
      notifyStatusFrequency
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const UPDATE_NOTIFICATION_PREFERENCES_SPACE = graphql(`
  mutation UpdateNotificationPreferencesSpace($input: UpdateNotificationPreferencesSpaceInput!) {
    updateNotificationPreferencesSpace(input: $input) {
      id
      spaceId
      userId
      notifyAddedToProject
      notifyProjectCreated
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const UPDATE_SLACK_DATA_CHANNEL = graphql(`
  mutation UpdateSlackDataChannel($input: UpdateSlackDataChannelInput!) {
    updateSlackDataChannel(input: $input) {
      organizationId
      collection
      objectId
      id
      name
    }
  }
`)

export const DELETE_SLACK_DATA_CHANNEL = graphql(`
  mutation DeleteSlackDataChannel($input: DeleteSlackDataChannelInput!) {
    deleteSlackDataChannel(input: $input)
  }
`)

export const CREATE_NOTIFICATION_PREFERENCES_USER = graphql(`
  mutation CreateNotificationPreferencesUser($input: CreateNotificationPreferencesUserInput!) {
    createNotificationPreferencesUser(input: $input) {
      id
      userId
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const UPDATE_NOTIFICATION_PREFERENCES_USER = graphql(`
  mutation UpdateNotificationPreferencesUser($input: UpdateNotificationPreferencesUserInput!) {
    updateNotificationPreferencesUser(input: $input) {
      id
      userId
      notifyEmail
      notifySlack
      muteAll
    }
  }
`)

export const LEAVE_SPACE = graphql(`
  mutation LeaveSpace($id: String!) {
    leaveSpace(id: $id)
  }
`)

export const JOIN_SPACE = graphql(`
  mutation JoinSpace($id: String!) {
    joinSpace(id: $id) {
      id
    }
  }
`)

export const UPDATE_ORGANIZATION_USER = graphql(`
  mutation UpdateOrganizationUser($organizationId: String!, $role: OrganizationUserRole!, $userId: String!) {
    updateOrganizationUser(organizationId: $organizationId, role: $role, userId: $userId) {
      id
    }
  }
`)

export const UPDATE_PROJECT_USER = graphql(`
  mutation UpdateProjectUser($id: String!, $input: UpdateProjectUserInput!) {
    updateProjectUser(id: $id, input: $input) {
      id
      role
    }
  }
`)

export const UPDATE_PROJECT = graphql(`
  mutation UpdateProject($id: String!, $input: UpdateProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
      name
      description
      projectUrl
    }
  }
`)

export const LEAVE_PROJECT = graphql(`
  mutation LeaveProject($id: String!) {
    leaveProject(id: $id)
  }
`)

export const DELETE_INTEGRATION = graphql(`
  mutation DeleteIntegration($input: DeleteIntegrationInput!) {
    deleteIntegration(input: $input)
  }
`)

export const CREATE_PROJECT_TASK_ATTACHMENT_UPLOAD_URL = graphql(`
  mutation createProjectTaskAttachmentUploadUrl($input: CreateTaskAttachmentUploadUrlInput!) {
    createProjectTaskAttachmentUploadUrl(input: $input) {
      signedUrl
      fileKey
    }
  }
`)

export const CREATE_ACTIVITY_TASK_ATTACHMENT_UPLOAD_URL = graphql(`
  mutation CreateActivityTaskAttachmentUploadUrl($input: CreateTaskAttachmentUploadUrlInput!) {
    createActivityTaskAttachmentUploadUrl(input: $input) {
      signedUrl
      fileKey
    }
  }
`)

export const UPDATE_SUGGESTION = graphql(`
  mutation updateSuggestion($input: ResolveSuggestionInput!) {
    updateSuggestion(input: $input)
  }
`)

export const GENERATE_SUGGESTIONS_FOR_ASSET = graphql(`
  mutation queueSuggestionsJobForAsset($input: UpdateAssetWithSuggestionsInput!) {
    queueSuggestionsJobForAsset(input: $input)
  }
`)

export const IGNORE_SUGGESTIONS_FOR_ASSET = graphql(`
  mutation ignoreSuggestionsForAsset($assetId: String!) {
    ignoreSuggestionsForAsset(assetId: $assetId)
  }
`)

export const GET_OR_CREATE_LIVEBLOCKS_ROOM = graphql(`
  mutation GetOrCreateLiveblocksRoom($input: CreateRoomForAssetInput!) {
    getOrCreateLiveblocksRoomForAsset(input: $input) {
      id
      createdAt
      lastConnectionAt
    }
  }
`)

export const CREATE_PROGRAM = graphql(`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      id
    }
  }
`)

export const UPDATE_PROGRAM = graphql(`
  mutation UpdateProgram($id: String!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
      id
    }
  }
`)

export const DELETE_PROGRAM = graphql(`
  mutation DeleteProgram($id: String!) {
    deleteProgram(id: $id)
  }
`)

export const CREATE_PARTNERSHIP = graphql(`
  mutation CreatePartnership($input: CreatePartnershipInput!) {
    createPartnership(input: $input) {
      id
      sponsorOrganizationId
      partnerOrganizationId
    }
  }
`)

export const UPDATE_PARTNERSHIP = graphql(`
  mutation UpdatePartnership($input: UpdatePartnershipInput!) {
    updatePartnership(input: $input) {
      id
      sponsorOrganizationId
      partnerOrganizationId
      onboardingStartTime
      onboardingFinishTime
    }
  }
`)

export const DELETE_PARTNERSHIP = graphql(`
  mutation DeletePartnership($input: DeletePartnershipInput!) {
    deletePartnership(input: $input)
  }
`)

export const CREATE_MOSAIC_PARTNERSHIP = graphql(`
  mutation CreateMosaicPartnership($input: CreateMosaicPartnershipInput!) {
    createMosaicPartnership(input: $input) {
      id
      sponsorOrganizationId
      partnerOrganizationId
    }
  }
`)

export const INITIATE_PARTNER_ONBOARDING = graphql(`
  mutation initiatePartnerOnboarding($url: String!) {
    initiatePartnerOnboarding(url: $url) {
      organization {
        metadata {
          legalNames
        }
      }
      licences {
        metadata {
          legalName
        }
      }
      links {
        href
      }
      notableNews {
        metadata {
          snippet
        }
      }
    }
  }
`)

export const CREATE_PROCESS = graphql(`
  mutation CreateProcess($input: CreateProcessInput!) {
    createProcess(input: $input) {
      ...ProcessFields
    }
  }
`)

export const UPDATE_PROCESS = graphql(`
  mutation UpdateProcess($input: UpdateProcessInput!) {
    updateProcess(input: $input) {
      ...ProcessFields
    }
  }
`)

export const CREATE_PROCESS_MEMBERS = graphql(`
  mutation CreateProcessMembers($input: CreateProcessMembersInput!) {
    createProcessMembers(input: $input) {
      ...ProcessMemberFields
    }
  }
`)

export const SAVE_PROCESS_FIELDS = graphql(`
  mutation SaveProcessFields($input: [CreateProcessFieldInput!]!, $processId: String!) {
    saveProcessFields(input: $input, processId: $processId) {
      ...ProcessFieldFields
    }
  }
`)

export const DELETE_PROCESS_FIELDS = graphql(`
  mutation DeleteProcessFields($id: String!) {
    removeProcessField(id: $id)
  }
`)

export const SET_PROCESS_MEMBERS = graphql(`
  mutation SetProcessMembers($input: CreateProcessMembersInput!) {
    setProcessMembers(input: $input) {
      ...ProcessMemberFields
    }
  }
`)

export const CREATE_ISSUES_FOR_PARTNER_SOURCE = graphql(`
  mutation CreateIssuesForPartnerSource($partnerSourceId: String!) {
    createIssuesForPartnerSource(partnerSourceId: $partnerSourceId) {
      id
    }
  }
`)

export const CREATE_ISSUES_FOR_PARTNERSHIP = graphql(`
  mutation CreateIssuesForPartnership($partnershipId: String!) {
    createIssuesForPartnership(partnershipId: $partnershipId) {
      id
    }
  }
`)

export const CREATE_PARTNER_SOURCE = graphql(`
  mutation CreatePartnerSource($input: CreatePartnerSourceInput!) {
    createPartnerSource(input: $input) {
      id
    }
  }
`)

export const UPDATE_PARTNER_SOURCE = graphql(`
  mutation UpdatePartnerSource($input: UpdatePartnerSourceInput!) {
    updatePartnerSource(input: $input) {
      id
    }
  }
`)

export const DELETE_PARTNER_SOURCE = graphql(`
  mutation DeletePartnerSource($partnerSourceId: String!) {
    deletePartnerSource(partnerSourceId: $partnerSourceId) {
      id
    }
  }
`)

// this goes to the monitoring resolver
export const RUN_PARTNER_SOURCE = graphql(`
  mutation RunSinglePartnerSource($partnerSourceId: String!) {
    runSinglePartnerSource(partnerSourceId: $partnerSourceId)
  }
`)

export const DELETE_ISSUE = graphql(`
  mutation DeleteIssue($issueId: String!) {
    deleteIssue(issueId: $issueId) {
      id
    }
  }
`)

export const CREATE_RULE = graphql(`
  mutation CreateRule($input: CreateRuleInput!) {
    createRule(input: $input) {
      id
    }
  }
`)

export const UPDATE_RULE = graphql(`
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY = graphql(`
  mutation UpdateActivity($id: String!, $input: UpdateActivityInput!) {
    updateActivity(id: $id, input: $input) {
      id
    }
  }
`)

export const DELETE_ACTIVITY = graphql(`
  mutation DeleteActivity($id: String!) {
    deleteActivity(id: $id)
  }
`)

export const DELETE_ACTIVITIES = graphql(`
  mutation DeleteActivities($ids: [String!]!) {
    deleteActivities(ids: $ids)
  }
`)

export const CREATE_ACTIVITY = graphql(`
  mutation CreateActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      id
    }
  }
`)

export const DELETE_MOSAIC_PARTNERSHIP = graphql(`
  mutation DeleteMosaicPartnership($input: DeletePartnershipInput!) {
    deleteMosaicPartnership(input: $input)
  }
`)

export const DELETE_ACTIVITY_SUBSCRIPTION = graphql(`
  mutation DeleteActivitySubscription($id: String!) {
    deleteActivitySubscription(id: $id)
  }
`)

export const DELETE_ACTIVITY_SUBSCRIPTION_CASCADE = graphql(`
  mutation DeleteActivitySubscriptionCascade($id: String!, $cascade: Boolean!) {
    deleteActivitySubscriptionCascade(id: $id, cascade: $cascade)
  }
`)

export const CREATE_ACTIVITY_SUBSCRIPTION = graphql(`
  mutation CreateActivitySubscription($input: CreateActivitySubscriptionInput!) {
    createActivitySubscription(input: $input) {
      id
      activity {
        id
      }
      organization {
        id
      }
      sourceBank {
        id
      }
      description
      status
      inheritedFromBank
      optedOut
      createdAt
      updatedAt
      deletedAt
    }
  }
`)

export const CREATE_BANK_ACTIVITY_SUBSCRIPTION = graphql(`
  mutation CreateBankSubscription($input: CreateBankSubscriptionInput!) {
    createBankSubscription(input: $input) {
      id
      activity {
        id
      }
      organization {
        id
      }
      sourceBank {
        id
      }
      description
      status
      inheritedFromBank
      optedOut
      createdAt
      updatedAt
      deletedAt
    }
  }
`)

export const ADD_ACTIVITY_TASK_COMMENT = graphql(`
  mutation AddActivityTaskComment($input: AddActivityTaskCommentInput!) {
    addCommentToActivityTask(input: $input) {
      id
      content
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
      }
    }
  }
`)

export const ADD_ASSET_TO_ACTIVITY_TASK = graphql(`
  mutation AddAssetToActivityTask($input: AddAssetToActivityTaskInput!) {
    addAssetToActivityTask(input: $input) {
      id
    }
  }
`)

export const APPROVE_ACTIVITY_TASK = graphql(`
  mutation ApproveActivityTask($input: ApproveActivityTaskInput!) {
    approveActivityTask(input: $input) {
      id
    }
  }
`)

export const SUBMIT_ACTIVITY_TASK = graphql(`
  mutation SubmitActivityTask($input: SubmitActivityTaskInput!) {
    submitActivityTask(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY_TASK_ASSIGNEE = graphql(`
  mutation UpdateActivityTaskAssignee($input: UpdateActivityTaskAssigneeInput!) {
    updateActivityTaskAssignee(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY_TASK_STATUS = graphql(`
  mutation UpdateActivityTaskStatus($input: UpdateActivityTaskStatusInput!) {
    updateActivityTaskStatus(input: $input) {
      id
    }
  }
`)

export const UPDATE_ACTIVITY_TASK_DUE_DATE = graphql(`
  mutation UpdateActivityTaskDueDate($input: UpdateActivityTaskDueDateInput!) {
    updateActivityTaskDueDate(input: $input) {
      id
    }
  }
`)

export const LIFECYCLE_MARK_OUT_OF_SCOPE = graphql(`
  mutation MarkOutOfScope($input: MarkOutOfScopeInput!) {
    markOutOfScope(input: $input)
  }
`)

export const CREATE_ACTIVITY_TASK_FROM_SUBSCRIPTION = graphql(`
  mutation CreateActivityTaskFromSubscription($input: CreateActivityTaskFromSubscriptionIdInput!) {
    createActivityTaskFromSubscription(input: $input) {
      id
    }
  }
`)

export const SEND_LOG_ANALYSIS_INSIGHTS_CHAT_MESSAGE = graphql(`
  mutation SendLogAnalysisInsightsChatMessage($input: EnrichedFragmentQAInput!) {
    enrichmentQA(input: $input) {
      output
    }
  }
`)
