import { clsx, type ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const twMerge = extendTailwindMerge({
  classGroups: {
    backgroundPosition: ['bg-pos-0', 'bg-pos-y-100'],
    backgroundSize: ['bg-h-2x'],
    size: ['form-builder'],
    shadow: [
      {
        shadow: ['sm', 'md', 'lg', 'xl', '2xl', 'inner', 'focus', 'inset-unfocus', 'inset-focus', 'none'],
      },
    ],
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
