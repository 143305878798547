export enum TeamSize {
  '1_49' = '1-49',
  '50_99' = '50-99',
  '100_249' = '100-249',
  '250_499' = '250-499',
  '500_999' = '500-999',
  '1000_plus' = '1000+',
}
export enum TeamType {
  COMPLIANCE = 'Compliance',
  ENGINEERING = 'Engineering',
  FINANCE = 'Finance',
  HR = 'HR',
  LEGAL = 'Legal',
  MARKETING = 'Marketing',
  OPERATIONS = 'Operations',
  SALES = 'Sales',
  SUPPORT = 'Support',
}

export enum ProjectAssetType {
  DIGITAL_AD = 'Digital Ad',
  SOCIAL_MEDIA = 'Social Media',
  BILBOARD_AD = 'Bilboard Ad',
  PRINT_AD = 'Print Ad',
}

// We need this enum to be in this format to match the format generated in our GraphQL schema
// Keep this enum separate to the BE to maintain the order of the values
export enum ProjectTaskPriority {
  None = 'None',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum ProjectTaskStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  OVERDUE = 'OVERDUE', // derived and shown on certain pages
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum AssetUrlType {
  GOOGLE_DRIVE = 'google-drive',
  GOOGLE_DOCS = 'google-docs',
}

export enum AssetFileType {
  PDF = 'pdf',
  MICROSOFT_WORD = 'microsoft-word',
  IMAGE = 'image',
}

export type AssetItem = {
  id: string
  file: File
  name: string
  // questioning whether we will need url here since we would grab the text content of the url via our integrations
  // and handle this in the first step of the CreateProjectAsset dialog
  type: 'file' | 'url'
  url: string
}

export enum NarrativeDocumentTypeCategory {
  Contracts = 'Contracts',
  CustomerFacingCollateral = 'CustomerFacingColateral',
  PoliciesAndProcedures = 'PoliciesAndProcedures',
}

export enum MimeType {
  Doc = 'Doc',
  Docx = 'Docx',
  Html = 'Html',
  Jpeg = 'Jpeg',
  Jpg = 'Jpg',
  Pdf = 'Pdf',
  Png = 'Png',
  Svg = 'Svg',
  Text = 'Text',
  Webp = 'Webp',
  Zip = 'Zip',
  Mp4 = 'Mp4',
  Webm = 'Webm',
  Mov = 'Mov',
}

/**
 * Converts a files type to a MimeType
 * Defaults to html.
 */
export const intoMimeType = (mimeType: string): MimeType => {
  switch (mimeType) {
    case 'application/msword':
      return MimeType.Doc
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return MimeType.Docx
    case 'text/html':
      return MimeType.Html
    case 'image/jpeg':
      return MimeType.Jpeg
    case 'image/jpg':
      return MimeType.Jpg
    case 'application/pdf':
      return MimeType.Pdf
    case 'image/png':
      return MimeType.Png
    case 'image/svg+xml':
      return MimeType.Svg
    case 'text/plain':
      return MimeType.Text
    case 'image/webp':
      return MimeType.Webp
    case 'application/zip':
      return MimeType.Zip
    case 'video/mp4':
      return MimeType.Mp4
    case 'video/webm':
      return MimeType.Webm
    case 'video/quicktime':
      return MimeType.Webm
    default:
      return MimeType.Html
  }
}

export enum WorkflowNodeType {
  NO_OP = 'NO_OP',
  ASSIGN_TASK = 'ASSIGN_TASK',
  ADD_COLLABORATORS_TASK = 'ADD_COLLABORATORS_TASK',
  UNASSIGN_TASK = 'UNASSIGN_TASK',
  SET_TASK_PRIORITY = 'SET_TASK_PRIORITY',
  SLACK_SEND_MESSAGE = 'SLACK_SEND_MESSAGE',
  SEND_EMAIL = 'SEND_EMAIL',
  NOTIFY_SLACK_CHANNEL = 'NOTIFY_SLACK_CHANNEL',
  NOTIFY_SLACK_USER = 'NOTIFY_SLACK_USER',
  AI_REVIEW = 'AI_REVIEW',
  SET_TASK_DATE = 'SET_TASK_DATE',
  EXECUTION_DELAY = 'EXECUTION_DELAY',
  ARCHIVE_TASK = 'ARCHIVE_TASK',
  REMOVE_TASK_DATE = 'REMOVE_TASK_DATE',

  ON_TASK_ASSIGNED = 'ON_TASK_ASSIGNED',
  ON_TASK_ASSIGNED_TO_SPECIFIC_USER = 'ON_TASK_ASSIGNED_TO_SPECIFIC_USER',
  ON_TASK_UNASSIGNED = 'ON_TASK_UNASSIGNED',
  ON_TASK_PRIORITY_SET = 'ON_TASK_PRIORITY_SET',
  ON_TASK_STATUS_SET = 'ON_TASK_STATUS_SET',
  ON_TASK_DATE_SET = 'ON_TASK_DATE_SET',
  ON_TASK_COMMENT_ADDED = 'ON_TASK_COMMENT_ADDED',
  ON_TASK_ATTACHMENT_ADDED = 'ON_TASK_ATTACHMENT_ADDED',
  ON_DOCUMENT_ASSET_HUMAN_REVIEWED = 'ON_DOCUMENT_ASSET_HUMAN_REVIEWED',
}

export enum WorkflowNodeInvocationType {
  TRIGGER = 'TRIGGER',
  ACTION = 'ACTION',
}

/**
 * ActionType for an audit trail, defined as a regular enum as this will receive regular
 * updates that should not necessitate a DB migration
 */
export enum AuditTrailActionType {
  UPDATE_USER = 'UPDATE_USER',
  INVITE_ORGANIZATION_MEMBERS = 'INVITE_ORGANIZATION_MEMBERS',
  INVITE_ORGANIZATION_MEMBER = 'INVITE_ORGANIZATION_MEMBER',
  CANCEL_ORGANIZATION_INVITATION = 'CANCEL_ORGANIZATION_INVITATION',
  ACCEPT_ORGANIZATION_INVITATION = 'ACCEPT_ORGANIZATION_INVITATION',
  INVITE_PROJECT_MEMBERS = 'INVITE_PROJECT_MEMBERS',
  INVITE_SPACE_MEMBERS = 'INVITE_SPACE_MEMBERS',
  ACCEPT_SPACE_INVITATION = 'ACCEPT_SPACE_INVITATION',
  ACCEPT_PROJECT_INVITATION = 'ACCEPT_PROJECT_INVITATION',
  CANCEL_PROJECT_INVITATION = 'CANCEL_PROJECT_INVITATION',
  CANCEL_SPACE_INVITATION = 'CANCEL_SPACE_INVITATION',
  CREATE_NOTIFICATION_PREFERENCES_USER = 'CREATE_NOTIFICATION_PREFERENCES_USER',
  UPDATE_NOTIFICATION_PREFERENCES_USER = 'UPDATE_NOTIFICATION_PREFERENCES_USER',
  UPDATE_ORGANIZATION_USER = 'UPDATE_ORGANIZATION_USER',
  UPDATE_PROJECT_USER = 'UPDATE_PROJECT_USER',
  LEAVE_PROJECT = 'LEAVE_PROJECT',
  LEAVE_SPACE = 'LEAVE_SPACE',
  JOIN_SPACE = 'JOIN_SPACE',
  LEAVE_ORGANIZATION = 'LEAVE_ORGANIZATION',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_METADATA = 'UPDATE_ORGANIZATION_METADATA',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  UPDATE_SLACK_DATA_CHANNEL = 'UPDATE_SLACK_DATA_CHANNEL',
  DELETE_SLACK_DATA_CHANNEL = 'DELETE_SLACK_DATA_CHANNEL',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  UPDATE_NOTIFICATION_PREFERENCES_PROJECT = 'UPDATE_NOTIFICATION_PREFERENCES_PROJECT',
  CREATE_PROJECT_TASK = 'CREATE_PROJECT_TASK',
  UPDATE_PROJECT_TASK = 'UPDATE_PROJECT_TASK',
  ARCHIVE_PROJECT_TASK = 'ARCHIVE_PROJECT_TASK',
  CREATE_PROJECT_TASK_ATTACHMENTS = 'CREATE_PROJECT_TASK_ATTACHMENTS',
  UPDATE_PROJECT_TASK_STATUS = 'UPDATE_PROJECT_TASK_STATUS',
  UPDATE_PROJECT_ASSET = 'UPDATE_PROJECT_ASSET',
  BUMP_ASSET_VERSION = 'BUMP_ASSET_VERSION',
  CREATE_OR_UPDATE_DOCUMENT_ASSET_REVISIONS = 'CREATE_OR_UPDATE_DOCUMENT_ASSET_REVISIONS',
  CREATE_ASSET_REVIEW_REQUEST = 'CREATE_ASSET_REVIEW_REQUEST',
  UPDATE_ASSET_REVIEW_REQUEST = 'UPDATE_ASSET_REVIEW_REQUEST',
  CREATE_PROJECT_TASK_ATTACHMENT_UPLOAD_URL = 'CREATE_PROJECT_TASK_ATTACHMENT_UPLOAD_URL',
  ADVANCE_WORKFLOW = 'ADVANCE_WORKFLOW',
  CREATE_SPACE = 'CREATE_SPACE',
  UPDATE_SPACE = 'UPDATE_SPACE',
  DELETE_SPACE = 'DELETE_SPACE',
  ARCHIVE_SPACE = 'ARCHIVE_SPACE',
  UPDATE_NOTIFICATION_PREFERENCES_SPACE = 'UPDATE_NOTIFICATION_PREFERENCES_SPACE',
  UPDATE_SPACE_USER = 'UPDATE_SPACE_USER',
  CREATE_WORKFLOW_TEMPLATE = 'CREATE_WORKFLOW_TEMPLATE',
  UPDATE_WORKFLOW_TEMPLATE = 'UPDATE_WORKFLOW_TEMPLATE',
  DELETE_WORKFLOW_TEMPLATE = 'DELETE_WORKFLOW_TEMPLATE',
  ARCHIVE_WORKFLOW_TEMPLATE = 'ARCHIVE_WORKFLOW_TEMPLATE',
  UPDATE_WORKFLOW_TEMPLATE_ACTIVE_STATUS = 'UPDATE_WORKFLOW_TEMPLATE_ACTIVE_STATUS',
  CREATE_PROJECT_WORKFLOW_TEMPLATE = 'CREATE_PROJECT_WORKFLOW_TEMPLATE',
  UPDATE_PROJECT_WORKFLOW_TEMPLATE = 'UPDATE_PROJECT_WORKFLOW_TEMPLATE',
  CREATE_COMMENT_THREAD = 'CREATE_COMMENT_THREAD',
  UPDATE_COMMENT_THREAD = 'UPDATE_COMMENT_THREAD',
  DELETE_COMMENT_THREAD = 'DELETE_COMMENT_THREAD',
  CREATE_COMMENT = 'CREATE_COMMENT',
  UPDATE_COMMENT = 'UPDATE_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  GET_OR_CREATE_LIVEBLOCKS_ROOM = 'GET_OR_CREATE_LIVEBLOCKS_ROOM',
  CREATE_PROGRAM = 'CREATE_PROGRAM',
  UPDATE_PROGRAM = 'UPDATE_PROGRAM',
  DELETE_PROGRAM = 'DELETE_PROGRAM',
  CREATE_PARTNERSHIP = 'CREATE_PARTNERSHIP',
  UPDATE_PARTNERSHIP = 'UPDATE_PARTNERSHIP',
  DELETE_INTEGRATION = 'DELETE_INTEGRATION',
  UPDATE_SUGGESTION = 'UPDATE_SUGGESTION',
}

export enum IconColor {
  AMBER = 'amber',
  BLUE = 'blue',
  BRONZE = 'bronze',
  CYAN = 'cyan',
  GOLD = 'gold',
  GREEN = 'green',
  INDIGO = 'indigo',
  MAUVE = 'mauve',
  MINT = 'mint',
  OLIVE = 'olive',
  ORANGE = 'orange',
  PINK = 'pink',
  PLUM = 'plum',
  PURPLE = 'purple',
  RED = 'red',
  SKY = 'sky',
  VIOLET = 'violet',
  YELLOW = 'yellow',
}

export enum ProcessStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum ProcessAccess {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum ComparisonOperator {
  Equal = '=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  NotEqual = '!=',
}
