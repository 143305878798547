/**
 * Formats a date string into a human-readable format with short month name, numeric day, and numeric year.
 *
 * @param {string} date - The date string to be formatted.
 * @returns {string} - The formatted date string.
 *
 * @example
 * const date1 = '2024-07-25';
 * const humanReadableDate = getHumanReadableDate(date1);
 * console.log(humanReadableDate); // Outputs something like 'Jul 25, 2024'
 */
export const getHumanReadableDate = (date: string) => {
  return formatDate(date, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

/**
 * Formats a date string into a numeric month, numeric day, and two-digit year format.
 *
 * @param {string} date - The date string to be formatted.
 * @returns {string} - The formatted date string.
 *
 * @example
 * const date2 = '2024-07-25';
 * const monthDayYearDate = monthDayYear(date2);
 * console.log(monthDayYearDate); // Outputs something like '7/25/24'
 */
export const monthDayYear = (date: string) => formatDate(date, { month: 'numeric', day: 'numeric', year: '2-digit' })

/**
 * Formats a date string into a human-readable date and time format.
 * The time format can be either 12-hour or 24-hour based on the use12HourFormat flag.
 *
 * @param {string} date - The date string to be formatted.
 * @param {boolean} [use12HourFormat=true] - Whether to use 12-hour format for the time. Defaults to true.
 * @returns {string} - The formatted date and time string.
 *
 * @example
 * const date3 = '2024-07-25T14:30:00';
 * const humanReadableDateTime12Hour = getHumanReadableDateTime(date3, true);
 * console.log(humanReadableDateTime12Hour); // Outputs something like 'July 25, 2024, 02:30 PM'
 *
 * @example
 * const date4 = '2024-07-25T14:30:00';
 * const humanReadableDateTime24Hour = getHumanReadableDateTime(date4, false);
 * console.log(humanReadableDateTime24Hour); // Outputs something like 'July 25, 2024, 14:30'
 */
export const getHumanReadableDateTime = (date: string, use12HourFormat = true) => {
  const timeFormat = use12HourFormat
    ? { hour: '2-digit', minute: '2-digit', hour12: true }
    : { hour: '2-digit', minute: '2-digit' }
  return formatDate(date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...timeFormat,
  } as Intl.DateTimeFormatOptions)
}

/**
 * Formats a date string based on the provided Intl.DateTimeFormatOptions.
 *
 * @param {string} dateString - The date string to be formatted.
 * @param {Intl.DateTimeFormatOptions} options - The formatting options.
 * @returns {string} - The formatted date string or 'Invalid Date' if the input date string is invalid.
 *
 * @example
 * const date5 = '2024-07-25';
 * const customFormattedDate = formatDate(date5, {
 *   weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
 * });
 * console.log(customFormattedDate); // Outputs something like 'Thursday, July 25, 2024'
 */
const formatDate = (dateString: string, options: Intl.DateTimeFormatOptions) => {
  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) throw new Error('Invalid date')
    return date.toLocaleDateString('en-US', options)
  } catch (error) {
    console.error('Error parsing date:', (error as Error).message)
    return 'Invalid Date'
  }
}
