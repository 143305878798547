import { PropsWithChildren } from 'react'
import { Button, ButtonProps } from './Button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './Tooltip'

interface TooltipButtonProps extends ButtonProps {
  tooltip?: string
  tooltipDelay?: number
}

const TooltipButton = ({ tooltip, tooltipDelay = 100, ...buttonProps }: PropsWithChildren<TooltipButtonProps>) => {
  if (tooltip) {
    return (
      <TooltipProvider delayDuration={tooltipDelay}>
        <Tooltip>
          <TooltipTrigger>
            <Button {...buttonProps}>{buttonProps.children}</Button>
          </TooltipTrigger>
          <TooltipContent>{tooltip}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return <Button {...buttonProps}>{buttonProps.children}</Button>
}

export { TooltipButton }
