import { ColumnDef, flexRender, Row, RowData, type Table as TableType } from '@tanstack/react-table'

import { cn } from '../utils'
import { Flexbox } from './layout'
import { PhosphorIcon } from './PhosphorIcon'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table'
import { Text } from './typography'

interface DataTableProps<TData, TValue> {
  className?: string
  columns: ColumnDef<TData, TValue>[]
  table: TableType<TData>
  onRowClick?: (row: Row<TData>) => void
  emptyMessage?: string
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    className: string
  }
}

export function DataTable<TData, TValue>({
  className,
  columns,
  table,
  onRowClick,
  emptyMessage,
}: DataTableProps<TData, TValue>) {
  return (
    <div className={cn(className)}>
      <Table>
        <TableHeader className='border-none bg-table-header'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              <TableHead style={{ width: '8px', backgroundColor: 'var(--table-header)' }} /> {/* Start column */}
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    style={{ backgroundColor: 'var(--table-header)' }}
                    className={cn(header.column.columnDef.meta?.className, 'border-slate-300', {
                      'cursor-pointer select-none group': header.column.getCanSort(),
                    })}
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === 'asc'
                          ? 'Sort ascending'
                          : header.column.getNextSortingOrder() === 'desc'
                            ? 'Sort descending'
                            : 'Clear sort'
                        : undefined
                    }
                  >
                    <Flexbox className={header.column.columnDef.meta?.className} align='center' gap={2}>
                      <Text
                        size='caption'
                        className='min-width-0 truncate'
                        as={header.column.id === 'select' ? 'div' : 'span'}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                      {{
                        asc: <PhosphorIcon name='ArrowUp' />,
                        desc: <PhosphorIcon name='ArrowDown' />,
                        false: header.column.getCanSort() && (
                          <PhosphorIcon
                            name='ArrowsDownUp'
                            className='opacity-0 group-hover:opacity-100 transition-opacity'
                          />
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flexbox>
                  </TableHead>
                )
              })}
              <TableHead style={{ width: '8px', backgroundColor: 'var(--table-header)' }} /> {/* End column */}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className='cursor-pointer text-sm'
                onClick={(event) => {
                  const target = event.target

                  if (target instanceof HTMLElement) {
                    // The checkbox is excluded when selection is enabled, but td elements have the checkbox class.
                    const isCheckbox = target.tagName === 'BUTTON' && target.getAttribute('role') === 'checkbox'
                    const checkboxClass = target.classList.contains('checkbox')
                    if (!isCheckbox && !checkboxClass) {
                      event.stopPropagation()
                      onRowClick?.(row)
                    }
                  }
                }}
              >
                <TableCell style={{ width: '8px' }} />
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    className={cell.column.columnDef.meta?.className}
                    key={cell.id}
                    // style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
                <TableHead style={{ width: '8px' }} /> {/* End column */}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24 text-center'>
                {emptyMessage ?? 'No results.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
