import { cva, VariantProps } from 'class-variance-authority'
import { ComponentProps, forwardRef, HTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react'
import { cn } from '../utils'
import { Button } from './Button'

export const ICON_SIZE = 16

const config = cva(undefined, {
  variants: {
    size: {
      xs: 'h-input-xs w-input-xs text-xs',
      sm: 'h-input-sm w-input-sm text-sm',
      md: 'h-input-md w-input-md text-base',
      lg: 'h-input-lg w-input-lg text-lg',
      xl: 'h-input-xl w-input-xl text-xl',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type IconButtonProps = VariantProps<typeof config> & {
  onClick?: MouseEventHandler
  title?: string
  className?: string
  disabled?: boolean
  isActive?: boolean
  variant?: ComponentProps<typeof Button>['variant']
  activeClasses?: string
} & HTMLAttributes<HTMLButtonElement>

export const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<IconButtonProps>>(
  ({ className, children, onClick, isActive, size, variant = 'ghost', activeClasses = '', ...props }, ref) => {
    return (
      <Button
        ref={ref}
        type='button'
        onClick={onClick}
        variant={variant}
        size='icon'
        className={cn(config({ size }), isActive && `bg-gray-100 ${activeClasses}`, className)}
        {...props}
      >
        {children}
      </Button>
    )
  },
)
