import { EMAIL_REGEX, InvocationTypeToNodeTypeMap, WorkflowTriggerLabels } from '../constants'
import { MimeType, TeamSize, TeamType, WorkflowNodeInvocationType, WorkflowNodeType } from '../enums'

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^a-z0-9-]/g, '-') // Replace all non-alphanumberic characters except -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

// Convert 'ARTICLES_OF_INCORPORATION' to 'Articles of Incorporation', used for enum values
const lowercaseWords = new Set(['of', 'the', 'an', 'a'])
export const underscoreToText = (text: string) => {
  return text
    .split('_')
    .map((token, index) => {
      const lowercasedToken = token.toLowerCase()
      if (lowercaseWords.has(lowercasedToken) && index !== 0) {
        return lowercasedToken
      }
      return lowercasedToken[0].toUpperCase() + lowercasedToken.substring(1)
    })
    .join(' ')
}

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const isValidTeamSize = (input: TeamSize) => Object.values(TeamSize).includes(input)
export const isValidTeamType = (input: TeamType) => Object.values(TeamType).includes(input)
export const isValidEmail = (email: string) => {
  return EMAIL_REGEX.test(email)
}

const relativeTimeFormatter = new Intl.RelativeTimeFormat('en-US', {
  numeric: 'always',
  style: 'narrow',
})

export const getRelativeTimeFromDateString = (date: string) => {
  const dateObj = new Date(date)
  return getRelativeTime(dateObj)
}

export const getRelativeTime = (dateObj: Date) => {
  const now = new Date()
  const diff = dateObj.getTime() - now.getTime()

  const hours = Math.floor(diff / (1000 * 60 * 60))

  // if less than 1 hour get the relative time in minutes
  if (hours === -1) {
    const minutes = Math.ceil(diff / (1000 * 60))

    if (minutes === 0) {
      return 'now'
    }

    return relativeTimeFormatter.format(minutes, 'minutes')
  }

  // if less than 1 day get the relative time in hours
  if (hours < -24) {
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
    return relativeTimeFormatter.format(days, 'days')
  }

  return relativeTimeFormatter.format(hours, 'hours')
}

export function getInvocationTypeForNodeType(type: WorkflowNodeType): WorkflowNodeInvocationType {
  if (InvocationTypeToNodeTypeMap[WorkflowNodeInvocationType.TRIGGER].includes(type)) {
    return WorkflowNodeInvocationType.TRIGGER
  }

  // Return ACTION as default for any type not found in the TRIGGER array
  return WorkflowNodeInvocationType.ACTION
}

export function getLabelForNodeType(type: WorkflowNodeType): string {
  return WorkflowTriggerLabels[type as unknown as WorkflowNodeType] ?? 'Trigger Action'
}

export function isImage(mimeType: MimeType): boolean {
  const imageTypes = [MimeType.Jpg, MimeType.Jpeg, MimeType.Png, MimeType.Svg, MimeType.Webp]
  return imageTypes.includes(mimeType)
}

export function isVideo(mimeType: MimeType): boolean {
  const videoTypes = [MimeType.Mov, MimeType.Mp4, MimeType.Webm]
  return videoTypes.includes(mimeType)
}

/**
 * Check if the asset is a video or a zip file and return false
 */
export function shouldRunSuggestions(mimeType: MimeType | undefined): boolean {
  if (!mimeType) return false
  if (isVideo(mimeType)) return false
  const additionalExcludedMimeTypes = [MimeType.Zip]
  return !additionalExcludedMimeTypes.includes(mimeType)
}

export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/*
 * Helper function for converting a string into a number (not leetcode btw)
 */
export function convertLetterToNumber(str: string) {
  const start = 96
  const len = str.length
  const out = [...str.toLowerCase()].reduce((out, char, pos) => {
    const val = char.charCodeAt(0) - start
    const pow = Math.pow(26, len - pos - 1)
    return out + val * pow
  }, 0)
  return out
}

export function formatTicketId(ticketId: string | undefined): string {
  if (!ticketId) {
    return ''
  }

  // TODO: Remove - This is for ISSUE_FIXTURES since they have weird ticketIDs
  if (!ticketId.indexOf('/')) {
    return ticketId
  }

  const parts = ticketId.split('/')

  if (parts.length !== 2) {
    return ticketId
  }

  const [source, ticket] = parts
  const [, id] = ticket.split('-')

  const sourceAbbreviations: { [key: string]: string } = {
    website: 'WEB',
    cfpb: 'CFPB',
    reddit: 'REDDIT',
    tiktok: 'TIKTOK',
    trustpilot: 'TRUST',
  }

  const formattedSource = sourceAbbreviations[source.toLowerCase()] || source.toUpperCase()

  return `${formattedSource}-${id.slice(0, 4)}`
}

export function sortAndCountCharacters(text: string): string {
  const cleanText = text.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()

  const charCount = new Map<string, number>()
  for (const char of cleanText) {
    charCount.set(char, (charCount.get(char) || 0) + 1)
  }

  const result = Array.from(charCount.entries())
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([char, count]) => `${char}:${count}`)
    .join(',')

  return result
}

export function extractTikTokHandleFromUrl(url: string): string | null {
  const trimmedUrl = url.trim()
  const tiktokPattern = /^(https?:\/\/)?(www\.)?tiktok\.com\/@(.*)$/i
  const match = trimmedUrl.match(tiktokPattern)
  if (match && match[3]) {
    return match[3].trim()
  }
  return null
}

export function getRandomEnumValue<T extends { [key: string]: string }>(enumObj: T): T[keyof T] {
  const enumValues = Object.values(enumObj) as T[keyof T][]
  const randomIndex = Math.floor(Math.random() * enumValues.length)
  return enumValues[randomIndex]
}

/**
 * Calculate the percentage change between two values
 * @param recentValue - The recent value
 * @param startValue - The start value
 * @returns - The percentage change or undefined if startValue is 0 or undefined, or if recentValue is undefined
 */
export function calculatePercentageChange(
  recentValue: number | undefined,
  startValue: number | undefined,
): number | undefined {
  if (recentValue === undefined) {
    return undefined
  }

  if (startValue === undefined || startValue === 0) {
    return undefined
  }

  return ((recentValue - startValue) / startValue) * 100
}
