import { graphql } from '../../dto-types/src/gql/gql'

export const SPACES_FRAGMENT = graphql(`
  fragment SpacesQuery on Space {
    __typename
    id
    name
    description
    spaceUrl
    icon
    iconColor
    owner {
      id
    }
    organization {
      id
      name
      teamUrl
    }
    projects {
      id
      name
      projectUrl
      icon
      iconColor
    }
    users {
      user {
        id
      }
    }
  }
`)

export const SPACE_SEARCH_FRAGMENT = graphql(`
  fragment SpaceSearch on Space {
    __typename
    id
    name
    description
    spaceUrl
    createdAt
    updatedAt
    deletedAt
    owner {
      id
    }
    organization {
      id
      name
      teamUrl
    }
    projects {
      id
      name
      projectUrl
      icon
      iconColor
    }
    users {
      user {
        id
      }
    }
  }
`)

export const ASSETS_SEARCH_FRAGMENT = graphql(`
  fragment AssetSearch on Asset {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    user {
      id
      email
      firstName
      lastName
      avatarUrl
    }
    name
    key
    version
    type
    documentType
    state
    originalMimeType
  }
`)

export const ORGANIZATION_SEARCH_FRAGMENT = graphql(`
  fragment OrganizationSearch on Organization {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    name
    teamUrl
    teamSize
    metadata
  }
`)

export const PROJECT_SEARCH_FRAGMENT = graphql(`
  fragment ProjectSearch on Project {
    __typename
    id
    createdAt
    updatedAt
    deletedAt
    name
    projectUrl
    description
    isPublic
    createdBy {
      id
      firstName
      lastName
      email
    }
    space {
      name
    }
  }
`)

export const PAGINATION_SEARCH_FRAGMENT = graphql(`
  fragment PaginationSearch on PaginationMeta {
    totalItems
    itemsPerPage
    totalPages
    currentPage
  }
`)

export const AUDIT_TRAIL_FRAGMENT = graphql(`
  fragment AuditTrail on AuditTrail {
    __typename

    id
    organizationId

    createdAt
    contextId

    action

    entityId
    entityType

    sourceId
    sourceType

    metadata
  }
`)

export const PROCESSES_FRAGMENT = graphql(`
  fragment ProcessFields on Process {
    __typename
    id
    status
    access
    title
    description
    space {
      id
      name
      icon
      iconColor
    }
    members {
      ...ProcessMemberFields
    }
    fields {
      ...ProcessFieldFields
    }
    createdAt
    updatedAt
    deletedAt
  }
`)

export const PROCESS_MEMBERS_FRAGMENT = graphql(`
  fragment ProcessMemberFields on ProcessMember {
    __typename
    id
    user {
      id
      email
      firstName
      lastName
      avatarUrl
    }
  }
`)

export const PROCESS_FIELDS_FRAGMENT = graphql(`
  fragment ProcessFieldFields on ProcessField {
    __typename
    id
    title
    description
    type
    isRequired
    options
  }
`)

export const ACTIVITY_TASK_FRAGMENT = graphql(`
  fragment ActivityTaskDetails on ActivityTask {
    __typename
    createdAt
    deletedAt
    description
    dueDate
    id
    submittingUserExplanation
    approvalUserExplanation
    submissionStatus
    submittedAt
    updatedAt
    summary
    assignedTo {
      id
      firstName
      lastName
      avatarUrl
    }
    submittedBy {
      id
      firstName
      lastName
      avatarUrl
    }
    approvedBy {
      id
      firstName
      lastName
      avatarUrl
    }
    subscription {
      id
      optedOut
      activity {
        id
        name
        description
        responsibleParty
        topic {
          id
          name
        }
        subtopic {
          id
          name
        }
      }
      organization {
        id
        name
      }
      sourceBank {
        id
        name
      }
    }
    comments {
      id
      content
      createdAt
      author {
        id
        firstName
        lastName
        avatarUrl
        email
      }
    }
    asset {
      id
      name
      key
      version
      originalMimeType
      createdAt
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
    attachments {
      id
      name
      fileKey
    }
  }
`)

export const ACTIVITY_FRAGMENT = graphql(`
  fragment ActivityDetails on Activity {
    id
    name
    description
    updateFrequency
    responsibleParty
    active
    createdAt
    updatedAt
    topic {
      id
      name
      description
      createdAt
    }
    subtopic {
      id
      name
      description
      createdAt
    }
  }
`)
