import { ComponentProps } from 'react'
import { Icon, IconNames } from './Icon'
import { MimeType } from '@thenarrative/common'

const mimeTypeToIcon = {
  [MimeType.Doc]: 'MicrosoftWordLogo',
  [MimeType.Docx]: 'MicrosoftWordLogo',
  [MimeType.Html]: 'MicrosoftWordLogo',
  [MimeType.Jpeg]: 'FileImage',
  [MimeType.Jpg]: 'FileImage',
  [MimeType.Pdf]: 'PDFLogo',
  [MimeType.Png]: 'FileImage',
  [MimeType.Svg]: 'FileImage',
  [MimeType.Text]: 'FileText',
  [MimeType.Webp]: 'FileImage',
  [MimeType.Zip]: 'Archive',
  [MimeType.Mp4]: 'Video',
  [MimeType.Webm]: 'Video',
  [MimeType.Mov]: 'Video',
} as const satisfies Record<MimeType, IconNames>

export interface FileIconProps extends Omit<ComponentProps<typeof Icon>, "name"> {
  mimeType: MimeType
}

export const FileIcon: React.FC<FileIconProps> = ({ mimeType, ...props }) => {
  const name = mimeTypeToIcon[mimeType] || 'File'
  return <Icon name={name} {...props} />
}
